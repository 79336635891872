(function() {
    window.apos = window.apos || {};
    var data = document.body && document.body.getAttribute('data-apos');
    Object.assign(window.apos, JSON.parse(data || '{}'));
    if (data) {
      document.body.removeAttribute('data-apos');
    }
    if (window.apos.modules) {
      for (const module of Object.values(window.apos.modules)) {
        if (module.alias) {
          window.apos[module.alias] = module;
        }
      }
    }
})();import index_0App from "/home/romain/robustcircuit-cms-website/node_modules/apostrophe/modules/@apostrophecms/util/ui/src/index.js";


import index_1App from "/home/romain/robustcircuit-cms-website/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/src/index.js";


import index_2App from "/home/romain/robustcircuit-cms-website/node_modules/apostrophe/modules/@apostrophecms/video-widget/ui/src/index.js";


import index_3App from "/home/romain/robustcircuit-cms-website/modules/asset/ui/src/index.js";


import index_4App from "/home/romain/robustcircuit-cms-website/node_modules/@apostrophecms/form/ui/src/index.js";


import index_5App from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-widget/ui/src/index.js";


import index_6App from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-text-field-widget/ui/src/index.js";


import index_7App from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-textarea-field-widget/ui/src/index.js";


import index_8App from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-select-field-widget/ui/src/index.js";


import index_9App from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-radio-field-widget/ui/src/index.js";


import index_10App from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-file-field-widget/ui/src/index.js";


import index_11App from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-checkboxes-field-widget/ui/src/index.js";


import index_12App from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-boolean-field-widget/ui/src/index.js";


import index_13App from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-conditional-widget/ui/src/index.js";



import index_0Stylesheet from "/home/romain/robustcircuit-cms-website/node_modules/apostrophe/modules/@apostrophecms/image-widget/ui/src/index.scss";


import index_1Stylesheet from "/home/romain/robustcircuit-cms-website/modules/asset/ui/src/index.scss";


import index_2Stylesheet from "/home/romain/robustcircuit-cms-website/node_modules/@apostrophecms/form/ui/src/index.scss";


import index_3Stylesheet from "/home/romain/robustcircuit-cms-website/modules/@apostrophecms/form-widget/ui/src/index.scss";index_0App();
index_1App();
index_2App();
index_3App();
index_4App();
index_5App();
index_6App();
index_7App();
index_8App();
index_9App();
index_10App();
index_11App();
index_12App();
index_13App();